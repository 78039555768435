App.factory(
	'MyEdriveRouteService',
	($http, $q, $rootScope, $interval, ApiService) =>
	{
		let polling_timer;

		let routes_updated_ts = Date.now();
		let routes = null;


		$rootScope.$watch('$user', n =>
		{
			if(!n && polling_timer)
			{
				$interval.cancel(polling_timer);
				polling_timer = null;
			}
		});


		var get_driving_route_gesamtfahrten = (route) => route.frequency.frequency_factor * route.frequency.frequency_multiplier * (route.frequency.back_and_forth && 2 || 1);
		var get_driving_route_distance = (route) => route.distance || 0;
		var get_driving_route_yearly_distance = (route) => get_driving_route_distance(route) * get_driving_route_gesamtfahrten(route);


		return {
			startPolling() {
				if(polling_timer)
				{
					return;
				}
				polling_timer = $interval(() =>
				{
					// dont poll if no session is active
					if(!ApiService.getSessionId())
					{
						return;
					}
					ApiService.callApi({
						method: 'driving_routes',
						params: {},
						success(data, status, headers) {
							if(Date.create(headers("Last-Modified")) != Date.create(routes_updated_ts))
							{
								console.log('MyEdriveTrackService: server routes update');
								routes_updated_ts = Date.create(headers("Last-Modified")).getTime();
								routes = data.data
							}
						},
						error:  angular.noop
					});
				}, 10000);
			},

			stopPolling() {
				if(polling_timer)
				{
					$interval.cancel(polling_timer);
					polling_timer = null;
				}
			},

			getRoutes() {
				if(polling_timer && routes)
				{
					return $q(resolve => {resolve(routes)})
				}
				else
				{
					return ApiService.callApi({
							method: 'driving_routes',
							params: {},
							success(data) {
								routes = routes || [];
								routes.length = 0;
								data.data.each(f =>
								{
									f._pristine = angular.copy(f);
									routes.push(f);
								});
								return routes;
							},
							error:  null
						}
					);
				}
			},

			getRouteConsumptionForCar(uuid_route, uuid_car) {
				return ApiService.callApi({
						method:  'get_route_consumption_for_car',
						params:  {uuid_car: uuid_car, uuid: uuid_route},
						success: (data) => data.data,
						error:   null
					}
				);
			},

			getUpdatedTimestamp() {
				return routes_updated_ts;
			},

			touchUpdatedTimestamp() {
				routes_updated_ts = Date.now();
			},

			getQualIndicatorColor(val)
			{
				if(val === null) return `hsl(0,0%,77%)`;

				let intrpl = (start, end, max, value)=> Math.floor(start + (((end - start) / max) * value));

				const
					bad = [0, 79, 41],     //hsl(355,79%,41%)
					good = [76, 72, 43],     //76,72%,43%
					max = 1;
				let [h,s,l] = [intrpl(bad[0], good[0], max, val), intrpl(bad[1], good[1], max, val), intrpl(bad[2], good[2], max, val)];

				return `hsl(${h},${s}%,${l}%)`;
			},

			getJahresHäufigkeit: get_driving_route_gesamtfahrten,

			getJFL: get_driving_route_yearly_distance,

			getDistance: get_driving_route_distance,
		};
	}
);
